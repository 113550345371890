import React, {useRef, useState, useLayoutEffect}  from "react"
import * as styles from './style.module.sass'

import ProjectList from '../../organisms/ProjectList'
import BackgroundVideoPlayer from "../../atoms/BackgroundVideoPlayer"
import AboutIntroduction from '../../molecules/AboutIntroduction'
import SectionTitle from "../../atoms/SectionTItle"
import NewsList from "../../organisms/NewsList"
import FvFooterNews from "../../atoms/FvFooterNews"
import FvFooter from "../../organisms/FvFooter"
import Footer from "../../organisms/Footer"

const IndexTemplate = (props) => {
  const layoutName = useRef('/');
  const scrollTarget = useRef(null);
  const isMobile = useRef(
    typeof window === 'undefined'? false :
    (/android|ipod|ipad|iphone|macintosh/.test(navigator.userAgent.toLowerCase()) && 'ontouchend' in document)?true : false
  );

  const containerDom = useRef(null);
  const containerWrapDom = useRef(null);

  useLayoutEffect(() => {
    if(isMobile.current) {
      containerDom.current.classList.add(styles.containerIsMobile);
      containerWrapDom.current.classList.add(styles.containerWrapIsMobile);
    }
  }, [])

  return (
    <>
      <div
        ref={containerDom}
        className={styles.container}
        id={"container"}
      >
        <div
          ref={containerWrapDom}
          className={styles.containerWrap}
        >
          <div className={styles.indexFv} >
            <BackgroundVideoPlayer
              layoutName={layoutName.current}
              videoId={props.mainVideo.indexVideoId}
              imageSP={props.mainVideo.indexVideoSpThumbnail.localFile}
              image={props.mainVideo.indexVideoThumbnail.localFile}
              title={'top main video'}
              isPaused={false}
              isTop={true}
              isOpacity={isMobile.current? false : true}
            />
            <ProjectList
              layoutName={layoutName.current}
              projectArray={props.projectArray}
            />
          </div>

          <div className={styles.indexFvNews}>
            <FvFooterNews
              layoutName={layoutName.current}
              newsList={props.newsList}
            />
          </div>

          <article className={styles.indexIntroduction} >
            <AboutIntroduction
              layoutName={layoutName.current}
              introduction={props.introduction}
            />
          </article>

          <section className={styles.indexNewsList}>
            <SectionTitle
              layoutName={layoutName.current}
              title={'News'}
            />
            <NewsList
              layoutName={layoutName.current}
              newsList={props.newsList}
            />
          </section>

          <Footer
            layoutName={layoutName.current}
          />
        </div>
      </div>
      <FvFooter
        layoutName={layoutName.current}
        newsList={props.newsList}
        scrollTarget={scrollTarget.current}
      />
    </>
  )
}

export default IndexTemplate
