import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/organisms/seo"
import IndexTemplate from "../components/template/IndexTemplate"

const IndexPage = ({ data }) => {
  return (
    <>
      <Seo title="Top" />
      <IndexTemplate
        mainVideo={data.topQuery.topMainVideo}
        projectArray={data.topQuery.topSelectedProject.selectedProject}
        introduction={data.aboutQuery.aboutIntroduction}
        newsList={data.newsList.edges}
      />
    </>
  )
}
export default IndexPage

export const pageQuery = graphql`
  query {
  
    topQuery: wpPage(slug: {eq: "top"}) {
      topMainVideo {
        indexVideoId
        indexVideoThumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP]
                breakpoints: [750, 2000]
                quality: 100
                width: 2000
              )
            }
          }
        }
        indexVideoSpThumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP]
                breakpoints: [1280]
                quality: 100
                width: 1280
              )
            }
          }
        }
      }
      topSelectedProject {
        selectedProject {
          ... on WpPost {
            id
            title
            slug
            projectTitleIsJp {
              isJpTitle
            }
            projectArchiveField {
              indexVimeoUrl
              archiveImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      breakpoints: [750, 2000]
                      quality: 100
                      width: 2000
                    )
                  }
                }
              }
              archiveSpImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      breakpoints: [1280]
                      quality: 100
                      width: 1280
                    )
                  }
                }
              }
            }
          }
        }
      }
    }

    aboutQuery: wpPage(slug: {eq: "about"}) {
      aboutIntroduction {
        introductionTitle
        introductionBody
      }
    }

    newsList: allWpNews(sort: {order: DESC, fields: date}, limit: 3) {
      edges {
        node {
          title
          slug
          newsContent {
            newsContentText
            newsContentImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    breakpoints: [480, 720]
                    quality: 100
                    width: 720
                  )
                }
              }
            }
          }
          date(formatString: "YY.MM.DD")
        }
      }
    }
  }
`
